<template>
  <div id="login">
    <div class="comment">
      <h1>登陆</h1>
    <form>
      <i class="el-icon-user" /> 账号:
      <input type="text" required="required" placeholder="用户名" v-model="userName"/>
      <i class="el-icon-edit" /> 密码:
      <input type="password" required="required" placeholder="密码" autocomplete v-model="password"/>
      <button class="but" type="button"  @click="login">登录</button>
      <button class="but" type="button"  @click="add">注册</button>
    </form>
    </div>
  </div>
</template>

<script>
import utils from '@/config/config.js'
export default {
  name: 'login',
  props: {
  },
  data () {
    return {
      userName: undefined,
      password: undefined,
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    toHref(href){
      var lastClassName = document.getElementsByClassName('navbarMaxAs');
      if(lastClassName){
        lastClassName.forEach(currentItem => {
          currentItem.style.boxShadow ='';
          
        });
      }
      this.lastHref = href;
      var className = document.getElementsByClassName(href);
      className[0].style.boxShadow ='inset 0px 4px 4px rgba(0, 0, 0, 0.25)';
    },
    routerPath (pa) {
      if (this.$route.path !== pa) {
        this.$router.push({ path: pa })
      }
    },
    login () {
      const parameters = {
        username: this.userName,
        password: this.password
      }
      if (this.userName !== undefined && this.userName !== '') {
        if (this.password !== undefined && this.password !== '') {
          let _this = this
          $.ajax({
            type: 'POST',
            url: utils.url + '/api/user/login',
            data: parameters,
            jsonp: utils.jsonp,
            success: function (data, state, ret) {
              if (ret.status === 200) {
                if (data.code === 200) {
                  _this.$message({
                    message: data.msg,
                    type: 'success'
                  })
                  _this.userName = ''
                  _this.password = ''
                  _this.$store.state.userName = data.data.nickName
                  _this.$store.state.isLogin = true
                  _this.$store.state.circleUrl = data.data.headPortrait
                  _this.$store.state.isAdmin = data.data.isAdmin
                  _this.toHref('a1')
                  _this.routerPath('/')
                } else {
                  _this.$message.error(data.msg)
                }
              } else {
                _this.$message.error('请重新登陆')
              }
            },
            beforeSend: utils.beforeSend,
            xhrFields: utils.xhrFields
          })
        } else {
          _this.$message.error('密码为空')
        }
      } else {
        _this.$message.error('账号为空')
      }
    },
    add () {
      console.log(this.userName)
      console.log(this.password)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#login{
    width: 100%;
    height: calc(100vh - 200px);
}
.comment {
  width: 400px;
  margin: 100px auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius:  30px 30px 30px 30px;
  background: #ffffff;
  padding: 50px;
}
#login h1{
    color: rgba(0, 0, 0, 0.548);
    letter-spacing: 1px;
    text-align: center;
}
input{
    width: 230px;
    min-height: 20px;
    margin-bottom: 10px;
    outline: none;
    padding: 10px;
    font-size: 13px;
    color: #000;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    background-color: #f5f5f5;
}
.but{
    width: 300px;
    min-height: 20px;
    background-color: #fff;
    border: 1px solid #fff;
    color: #000;
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    border-radius: 5px;
    margin-bottom: 10px;
    border-radius:  30px 30px 30px 30px;
    border: 1px solid #f5f5f5;
}
</style>
